import "../styles/app.scss";

import { useEffect } from "react";
import { DefaultSeo } from "next-seo";
import { SWRConfig } from "swr";
import { useRouter } from "next/router";
import Script from "next/script";
import * as ga from "../lib/ga";

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
  }
}

export default function Monokel({ Component, pageProps }) {
  const router = useRouter();

  // Attach client side navigation to links inside rich text wrappers
  useEffect(() => {
    function richTextLinkListener(event) {
      const target = event.target as HTMLAnchorElement;

      if (target.matches?.(".u-richText a")) {
        if (event.defaultPrevented || target.origin !== location.origin) {
          return;
        }

        event.preventDefault();
        router.push(target.href);
      }
    }

    document.addEventListener("click", richTextLinkListener);

    return () => {
      document.removeEventListener("click", richTextLinkListener);
    };
  });

  // Log page views to GA
  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <SWRConfig
      value={{
        fallback: pageProps.swrFallback,
        revalidateOnFocus: false,
      }}
    >
      <DefaultSeo
        title="Monokel Eyewear"
        openGraph={{
          type: "website",
          title:
            "Monokel Eyewear（モノケルアイウェア）スウェーデンのサステナブルサングラスブランド　プラントベースアセテート使用",
          description:
            "Monokel Eyewear（モノケルアイウェア）は、スウェーデンのストックホルムを拠点とするユニセックスのサングラスレーベルです。フレームの主な素材は高品質なプラントベースのアセテート。またオールブラックのフレームはリサイクルアセテートで作られています。レンズはCarl Zeiss Vision (カールツァイスビジョン)によって作られています。各フレームは、作るのに3か月以上かかり、細部まで細心の注意を払い手作業で作られています。私たちのブランド哲学は、シンプルさと機能性をミックスし、そこに人間の心とその創造的な表現に対する無限の好奇心を組み合わせ、生み出されています。",
          images: [
            {
              url: "https://monokel-eyewear.com/img/monokel-eyewear-1-og.jpeg",
              width: 639,
              height: 335,
              alt: "Monokel Eyewear",
            },
            {
              url: "https://monokel-eyewear.com/img/monokel-eyewear-2-og.jpeg",
              width: 639,
              height: 335,
              alt: "Monokel Eyewear",
            },
          ],
        }}
        additionalLinkTags={[
          {
            rel: "shortcut icon",
            href: "/favicon.svg",
          },
          {
            rel: "shortcut icon",
            href: "/favicon.png",
          },
          {
            rel: "preconnect",
            href: "https://monokel.centra.com",
          },
          {
            rel: "preconnect",
            href: "https://monokel.centracdn.net",
          },
        ]}
        additionalMetaTags={[
          {
            name: "facebook-domain-verification",
            content: "m7w0g25im7qcd8hhmw59asgrrmdzwm7",
          },
        ]}
      />
      <Script
        id="ingridTrackingWidget"
        src="https://cdn.ingrid.com/tracking-widget/bootstrap.js"
        strategy="beforeInteractive"
      />

      <Script id="gtm" strategy="afterInteractive">
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-5DBVB77');
      `}
      </Script>

      <Script
        async
        src="https://www.googletagmanager.com/gtag/js?id=GTM-5DBVB77"
      />

      <Script id="gtm-init" strategy="afterInteractive">
        {`
         window.dataLayer = window.dataLayer || [];
         function gtag(){window.dataLayer.push(arguments);}
         gtag('js', new Date());
       
         gtag('config', 'GTM-5DBVB77');
      `}
      </Script>

      <noscript
        dangerouslySetInnerHTML={{
          __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-5DBVB77" height="0" width="0" style="display: none; visibility: hidden;" />`,
        }}
      />

      <Component {...pageProps} />
    </SWRConfig>
  );
}
